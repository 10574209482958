import { useContext } from "react";
import AppContext from "./AppContext";
import { auth } from "./firebase-config";

export default function TopBar({ googleButton, resetState }) {
  const { uid, activePage, setActivePage } = useContext(AppContext);

  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        console.log("Signed Out");
      })
      .catch((error) => {
        console.error("Sign Out Error", error);
      });
  };

  // const clearAthletes = () => {
  //   const confirm = window.confirm(
  //     "Are you sure you want to clear all selected athletes?"
  //   );
  //   if (confirm) {
  //     setSelectedAthletes([]);
  //     setCampaignType("");
  //   }
  // };

  if (uid == null) {
    return (
      <div className="flex">
        <img
          src="./icons/logo.png"
          className="h-12 ml-12 mt-6 cursor-pointer"
          onClick={resetState}
        />
        <div className="text-center mr-12 ml-auto mt-5 cursor-pointer">
          {googleButton()}
        </div>
        {/* <div className="text-center mt-3 top-20 left-14 fixed bg-white p-4 px-8 rounded border z-50">
          <div className={`${activePage == "checkout" && "hidden"} flex-row`}>
            Limited availability for Fall!
            <div
              className={`border py-2 mt-4 bg-blue-700 text-white text-center font-semibold text-lg rounded px-6 py-1 hover:shadow cursor-pointer`}
              onClick={() => {
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSf7uAPUJiRjZaVHLe9IekYtp194Zvv8ZiclKai4Y7-oQWEJ-w/viewform",
                  "_blank"
                );
              }}
            >
              Book a campaign
            </div>
          </div>
        </div> */}
      </div>
    );
  }

  return (
    <div className="flex">
      <img
        src="./icons/logo.png"
        className="h-12 ml-12 mt-6 cursor-pointer"
        onClick={resetState}
      />

      {uid ? (
        <>
          <div className="text-center mr-8 ml-auto mt-4">
            <div className="flex">
              <div
                className={`${
                  activePage == "home" ? "border-slate-200" : "border-white"
                } hover:grayscale-0 grayscale border ml-6 text-center font-semibold text-sm rounded px-5 py-1 border hover:border-slate-200 cursor-pointer`}
                onClick={() => setActivePage("home")}
              >
                <img src="./icons/ui/deal.svg" className="w-5 h-5 mx-auto" />
                Campaigns
              </div>

              <div
                className={`${
                  activePage == "athletes" ? "border-slate-200" : "border-white"
                } hover:grayscale-0 grayscale  ml-6 text-center font-semibold text-sm rounded px-5 py-1 border hover:border-slate-200 cursor-pointer`}
                onClick={() => setActivePage("athletes")}
              >
                <img src="./icons/men.png" className="w-5 h-5 mx-auto" />
                Athletes
              </div>

              <div
                className="hover:grayscale-0 grayscale ml-6 text-center font-semibold text-sm rounded px-3 py-1 border border-white hover:border-slate-200 cursor-pointer"
                onClick={signOut}
              >
                <img
                  src="./icons/ui/power.svg"
                  className="w-5 h-5 p-0.5 pt-1 mx-auto"
                />
                Sign Out
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="text-center mr-12 ml-auto mt-5 cursor-pointer">
          {googleButton()}
        </div>
      )}
    </div>
  );
}
