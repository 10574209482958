import { createContext, useState } from "react";

interface AppContextValue {
  uid: string | undefined;
  setUid: React.Dispatch<React.SetStateAction<string | undefined>>;
  activePage: string;
  setActivePage: React.Dispatch<React.SetStateAction<string>>;
  selectedAthletes: any[];
  setSelectedAthletes: React.Dispatch<React.SetStateAction<any[]>>;
  campaignType: string;
  setCampaignType: React.Dispatch<React.SetStateAction<string>>;
  TYPE_PRICES: {
    reshare: number;
    photo: number;
    video: number;
  };
}

const defaultContextValue: AppContextValue = {
  uid: undefined,
  setUid: () => {},
  activePage: "home",
  setActivePage: () => {},
  selectedAthletes: [],
  setSelectedAthletes: () => {},
  campaignType: "",
  setCampaignType: () => {},
  TYPE_PRICES: {
    reshare: 0,
    photo: 10000,
    video: 50000,
  },
};

const AppContext = createContext<AppContextValue>(defaultContextValue);

export const AppProvider = ({ children }) => {
  const [uid, setUid] = useState<string | undefined>(undefined);
  const [activePage, setActivePage] = useState<string>("home");
  const [selectedAthletes, setSelectedAthletes] = useState<any[]>([]);
  const [campaignType, setCampaignType] = useState<string>("");
  const TYPE_PRICES = {
    reshare: 0,
    photo: 10000,
    video: 50000,
  };

  const contextValue = {
    uid,
    setUid,
    activePage,
    setActivePage,
    selectedAthletes,
    setSelectedAthletes,
    campaignType,
    setCampaignType,
    TYPE_PRICES,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export default AppContext;
