import { GoogleLogin } from "@react-oauth/google";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithCredential,
} from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import AllDeals from "./AllDeals";
import "./App.css";
import AppContext from "./AppContext";
import Athletes from "./Athletes";
import Campaigns from "./Campaigns";
import DynamicCampaignForm from "./CheckoutForms/DynamicCampaignForm";
import AthleteGrid from "./CreateCampaign/AthleteGrid";
import Toolbar from "./Toolbar";
import TopBar from "./TopBar";
import { auth } from "./firebase-config";

function Home() {
  const [activeSport, setActiveSport] = useState("popular");
  // const [searchQuery, setSearchQuery] = useState("");

  const { uid, setUid, activePage, setActivePage, campaignType } =
    useContext(AppContext);

  const resetState = () => {
    setActiveSport("popular");
    setActivePage("home");
  };

  useEffect(() => {
    if (campaignType == "") {
      resetState();
    }
  }, [campaignType]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.email?.split("@")[1] !== "launchpointhq.com") {
          alert("Book a campaign to access your dashboard");
          auth.signOut();
          return;
        }
        const uid = user.uid;
        setUid(uid);
      } else {
        setUid(undefined);
      }
    });
  }, []);

  const handleGoogleSignIn = async (response) => {
    const credential = GoogleAuthProvider.credential(response.credential);
    try {
      await signInWithCredential(auth, credential);
    } catch (error) {
      console.error(error);
      alert("Login Failed");
    }
  };

  const googleButton = () => {
    return (
      <GoogleLogin
        onSuccess={handleGoogleSignIn}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    );
  };
  {
    if (uid == null)
      return (
        <>
          <TopBar googleButton={googleButton} resetState={resetState} />
          <div className="h-1 border-b-[1px] mb-4 w-[100vw]"></div>
          <div className="w-full my-4 flex max-lg:flex-col items-center justify-center gap-12">
            <div className="max-lg:hidden">
              <iframe
                width="676"
                height="380"
                src="https://www.youtube.com/embed/1k937NdM5_k?si=jfQqNWNvn5hzG6lu&rel=0&controls=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen={true}
                className="mx-auto"
              ></iframe>
            </div>
            <div className="lg:hidden w-full px-24">
              <iframe
                width="100%"
                height="380"
                src="https://www.youtube.com/embed/1k937NdM5_k?si=jfQqNWNvn5hzG6lu&rel=0&controls=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen={true}
                className="mx-auto"
              ></iframe>
            </div>

            <div className="flex-col justify-center">
              <div className="flex-col mb-6">
                <div className="text-lg font-bold mb-1">1. Setup</div>
                <div>Set a maximum budget & add details</div>
                <div className="italic mt-2 text-sm">
                  ✓ No messaging back and forth
                </div>
              </div>

              <div className="flex-col mb-6">
                <div className="text-lg font-bold mb-1">2. Blast</div>
                <div>Verified athletes post on their story</div>
                <div className="italic mt-2 text-sm">✓ Pay per view</div>
              </div>
              <div className="flex-col mb-6">
                <div className="text-lg font-bold mb-1">3. Track</div>
                <div>
                  You recieve all content and analytics
                  <div className="italic mt-2 text-sm">✓ Link tracking</div>
                </div>
              </div>
              <div
                className={`border py-2 mt-4 text-white text-center font-semibold rounded px-6 py-1 hover:shadow cursor-pointer`}
                onClick={() => {
                  window.open("https://forms.gle/bvDcFx4FRjnQdDaT7", "_blank");
                }}
                style={{ backgroundColor: "rgb(119 51 226)" }}
              >
                Book a campaign
              </div>
            </div>
          </div>
          <Toolbar activeSport={activeSport} setActiveSport={setActiveSport} />
          <AthleteGrid activeSport={activeSport} searchQuery={""} />
        </>
      );
  }

  return (
    <div className="App">
      <TopBar googleButton={googleButton} resetState={resetState} />

      {activePage === "home" ? (
        <Campaigns />
      ) : activePage === "checkout" ? (
        <div className="w-[100vw]">
          <DynamicCampaignForm />
        </div>
      ) : activePage === "deals" ? (
        <div className="w-[100vw]">
          <AllDeals />
        </div>
      ) : activePage === "athletes" ? (
        <div className="w-[100vw]">
          <Athletes />
        </div>
      ) : (
        <div className="w-[100vw]">
          <Campaigns />
        </div>
      )}
    </div>
  );
}

export default Home;

{
  /* <input
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              type="text"
              className="mb-2 h-12 px-4 rounded-2xl border flex-grow"
              placeholder="Search athletes"
            />
            <select
              className="h-12 px-4 rounded-2xl ml-4"
              style={{
                borderRight: "16px solid transparent",
                outline: "1px solid #ddd",
              }}
            >
              <option value="all">All Schools</option>
              <option value="ohio">Ohio State</option>
            </select> */
}
