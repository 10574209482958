import { GoogleOAuthProvider } from "@react-oauth/google";
import "./App.css";
import Home from "./Home";

function App() {
  return (
    <GoogleOAuthProvider clientId="184835488165-4e3h6r7k92mkretrv31dhtasvlbv4e85.apps.googleusercontent.com">
      <Home />
    </GoogleOAuthProvider>
  );
}

export default App;
