function AthleteProfile({ className, athlete, onClick, isSelected }) {
  // const [isHovered, setIsHovered] = useState(false);

  // const openInstagram = (e) => {
  //   e.stopPropagation();
  //   window.open(
  //     "https://instagram.com/" + athlete.instagram.replace("@", ""),
  //     "_blank"
  //   );
  // };

  const handleImageError = async (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    e.currentTarget.src = "/empty.jpg";
  };

  return (
    <div
      className={`${className} ${
        isSelected
          ? "outline-blue-400 outline-offset-2 outline outline-2 rounded-lg"
          : ""
      } p-4 w-[300px] my-6 mx-2"`}
      onClick={onClick}
    >
      {/* <div
        className={`absolute rounded-md mt-1 ml-1 p-1 hover:bg-white bg-gray-100 z-40 flex text-xs ${
          isHovered ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={openInstagram}
      >
        Open Instagram
        <img src="./icons/ui/popout.svg" className="w-4 h-4 ml-1 mt-0.5" />
      </div> */}

      <img
        src={athlete.image}
        className="overflow-hidden rounded-lg w-full h-[300px] w-[300px] object-cover object-top"
        onError={handleImageError}
      />
      <div className="text-left mt-2">
        <div className="flex space-between w-full">
          <div>{athlete.name}</div>
          <div className="ml-auto">
            $15 <span className="opacity-70">cpm</span>
          </div>
        </div>
        <div className="">
          <div className="ml-auto flex text-blue-600">
            <img
              src="./icons/instagram.svg"
              className="w-4 h-4 mr-1 my-auto opacity-50"
            />
            <div
              className="my-auto cursor-pointer"
              onClick={() => {
                window.open(
                  `https://instagram.com/${athlete.instagram.replace("@", "")}`,
                  "_blank"
                );
              }}
            >
              {athlete.instagram}
            </div>
          </div>
          {/* <div className="opacity-70">{athlete.followers} average viewers</div> */}
        </div>
      </div>
    </div>
  );
}

export default AthleteProfile;
