import { httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { functions } from "./firebase-config";

export default function Deals({
  campaign,
  back,
}: {
  campaign?: any;
  back?: () => void;
}) {
  const [deals, setDeals] = useState<any[]>([]);
  const [athletesWithDeals, setAthletesWithDeals] = useState<string[]>([]);
  const [athletes, setAthletes] = useState<any[]>([]);
  const [selectedAthlete, setSelectedAthlete] = useState<any>();

  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [viewInput, setViewInput] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [isActing, setIsActing] = useState<string | undefined>(undefined);
  const [imageSrc, setImageSrc] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  const [deliveryDate, setDeliveryDate] = useState({});
  const [productCode, setProductCode] = useState({});

  useEffect(() => {
    deals.forEach((deal) => {
      if (deal.views) {
        console.log(viewInput);
        const newViewInput = viewInput;
        newViewInput[deal.id] = deal.views;
        setViewInput(newViewInput);
      }
    });
  }, [deals]);

  const filteredAthletes = athletes.filter(
    (athlete) =>
      athlete.instagram &&
      ((athlete.firstName ?? "" + " " + athlete.lastName ?? "")
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
        ("@" + athlete.instagram)
          .toLowerCase()
          .includes(searchTerm.toLowerCase()))
  );

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleSelectAthlete = (athlete) => {
    if (athletesWithDeals.includes(athlete.id)) {
      const c = confirm(
        "This person already has this deal. Do you want to offer it to them again?"
      );
      if (!c) {
        return;
      }
    }
    setSelectedAthlete(athlete);
    setSearchTerm("@" + athlete.instagram);
  };

  useEffect(() => {
    refreshDeals();
    getAllAthletes().then((athletes) => setAthletes(athletes as any[]));
  }, []);

  const refreshDeals = async () => {
    setIsLoading(true);
    getAllDeals()
      .then((campaigns) => {
        const filteredCampaigns = (campaigns as any[]).filter(
          (campaign) => campaign.details != null
        );
        setDeals(filteredCampaigns);
        setAthletesWithDeals(filteredCampaigns.map((deal) => deal.seller.id));
        setIsLoading(false);
        setIsActing(undefined);
      })
      .catch(() => {
        setIsLoading(false);
        setIsActing(undefined);
      });
  };

  const getAllDeals = async () => {
    const params = campaign
      ? { campaignId: campaign.id }
      : { status: filterStatus };
    const allDeals = httpsCallable(functions, "allDeals");
    const result = await allDeals(params);
    return result.data;
  };

  useEffect(() => {
    refreshDeals();
  }, [filterStatus]);

  const getAllAthletes = async () => {
    const allAthletes = httpsCallable(functions, "allAthletes");
    const result = await allAthletes({ verified: true });
    return result.data;
  };

  const offerToAthlete = async () => {
    setIsLoading(true);
    setSearchTerm("");
    const newOffer = httpsCallable(functions, "newOffer");
    await newOffer({
      athleteId: selectedAthlete.id,
      campaignId: campaign.id,
    });
    setTimeout(() => refreshDeals(), 1000);
  };

  const actOn = async (deal) => {
    try {
      setIsActing(deal.id);
      if (deal.status == "Not Accepted") {
        const recallOffer = httpsCallable(functions, "recallOffer");
        await recallOffer({
          dealId: deal.id,
        });
        setTimeout(() => refreshDeals(), 250);
      } else if (deal.status == "In Review") {
        const approvePayout = httpsCallable(functions, "approvePayout");
        await approvePayout({
          views: viewInput[deal.id],
          dealId: deal.id,
        });
        setTimeout(() => refreshDeals(), 250);
      } else if (
        deal.status == "Accepted" &&
        deal.details.physicalProduct == true
      ) {
        const markAsSent = httpsCallable(functions, "markAsSent");
        await markAsSent({
          dealId: deal.id,
          deliveryDate: deliveryDate[deal.id],
        });
        setTimeout(() => refreshDeals(), 250);
      }
    } catch (error) {
      alert("An error occurred. " + (error as any).message);
      setIsActing(undefined);
    }
  };

  const changeProductCode = async (deal, code) => {
    try {
      setIsActing(deal.id);
      const changeProductCode = httpsCallable(functions, "changeProductCode");
      await changeProductCode({
        dealId: deal.id,
        code: code,
      });
      setTimeout(() => refreshDeals(), 250);
    } catch (error) {
      alert("An error occurred. " + (error as any).message);
      setIsActing(undefined);
    }
  };

  const fetchImage = async (screenshot: string) => {
    setImageSrc("loading");
    try {
      if (screenshot == undefined || screenshot == "") {
        setImageSrc("");
        return;
      }
      const storage = getStorage();
      const url = await getDownloadURL(ref(storage, screenshot));
      console.log(url);
      setImageSrc(url);
    } catch (error) {
      console.log(error);
      setImageSrc("");
    }
  };

  const openMedia = async (media: string) => {
    try {
      const storage = getStorage();
      const url = await getDownloadURL(ref(storage, media));
      window.open(url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const cleanInsta = (insta: string) => {
    if (insta.startsWith("@")) {
      return insta.substring(1);
    }
    return insta;
  };

  return (
    <div className="deals mt-4">
      {campaign && (
        <div className="mx-12 mb-8">
          <div>
            <div className="text-md">
              <span className="font-semibold">{campaign.details.brand}</span>
              {" - "}
              {campaign.details.title}
            </div>
            <div className="text-sm mt-1">
              ({campaign.details.date}) {campaign.details.subtitle}
            </div>
          </div>
        </div>
      )}
      <div className="flex mb-4">
        {back && (
          <div
            className="ml-12 mr-12 font-bold text-left w-fit my-auto cursor-pointer"
            onClick={back}
          >
            ←Back
          </div>
        )}
        <div className="w-full mr-8 relative text-sm">
          {campaign ? (
            <>
              <input
                type="text"
                placeholder={
                  athletes.length == 0 ? "Loading athletes..." : "Add athlete"
                }
                className="p-2 border rounded w-full"
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={() => setIsOpen(true)}
                onBlur={() => setTimeout(() => setIsOpen(false), 200)}
              />
              {isOpen && (
                <ul className="absolute z-10 w-full bg-white border rounded mt-1 max-h-80 overflow-auto">
                  {/* <li
                  className="p-2 font-semibold hover:bg-gray-100 cursor-pointer text-left"
                  onClick={() => handleSelectAthlete({ instagram: "everyone" })}
                >
                  [Select all]
                </li> */}
                  {filteredAthletes
                    .sort((a, b) => {
                      if (athletesWithDeals.includes(a.id)) return 1;
                      if (athletesWithDeals.includes(b.id)) return -1;
                      return 0;
                    })
                    .map((athlete) => (
                      <li
                        key={athlete.id}
                        className={`p-2 hover:bg-gray-100 cursor-pointer text-left ${
                          athletesWithDeals.includes(athlete.id) && "opacity-50"
                        }`}
                        onClick={() => handleSelectAthlete(athlete)}
                      >
                        {athlete.firstName} {athlete.lastName} - @
                        {cleanInsta(athlete.instagram)}
                      </li>
                    ))}
                </ul>
              )}
            </>
          ) : (
            <select
              className="p-2 border rounded w-1/2"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="">All</option>
              <option value="In Review">In Review</option>
              <option value="Accepted">Accepted</option>
              <option value="Not Accepted">Not Accepted</option>
            </select>
          )}
        </div>
        {searchTerm != "" && (
          <div
            className={`cursor-pointer hover:shadow-md text-sm w-48 mr-8 rounded-md bg-blue-800 text-white font-semibold grid ${
              searchTerm == "" && "opacity-50 cursor-not-allowed"
            }`}
            onClick={offerToAthlete}
          >
            <div className="my-auto">Confirm</div>
          </div>
        )}
      </div>
      <div className="relative overflow-x-auto w-full flex">
        {isLoading && (
          <div className="fixed w-full bg-clear h-full z-10">
            <img src="./icons/ui/load.gif" className="w-16 mx-auto mt-12" />
          </div>
        )}
        <table
          className="mx-8 box-border text-sm text-left rtl:text-right text-gray-500"
          style={{ width: "calc(100% - 4rem)" }}
        >
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Athlete
              </th>
              <th scope="col" className="px-6 py-3">
                Instagram
              </th>
              {/* <th scope="col" className="px-6 py-3">
                Link clicks
              </th> */}
              <th scope="col" className="px-6 py-3">
                Views
              </th>
              <th scope="col" className="px-6 py-3">
                Proof
              </th>
              <th scope="col" className="px-6 py-3">
                Story Media
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {deals.map(
              (deal) =>
                deal.seller && (
                  <tr
                    className={`bg-white border-b hover:bg-gray-100 ${
                      isLoading && "blur-sm"
                    }`}
                    key={deal.id}
                  >
                    <td
                      className="px-6 py-4 cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(deal.id);
                        alert("Copied " + deal.id);
                      }}
                    >
                      {deal.status}
                    </td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {deal.seller.firstName} {deal.seller.lastName}
                      <br />
                      <div className="font-normal">
                        {deal.seller.address.street}{" "}
                        {deal.seller.address.streetTwo}{" "}
                        {deal.seller.address.city} {deal.seller.address.state}{" "}
                        {deal.seller.address.zip}
                      </div>
                    </th>
                    <td className="px-6 py-4">
                      <a
                        href={"https://instagram.com/" + deal.seller.instagram}
                        target="_blank"
                        className="text-blue-400 cursor-pointer"
                      >
                        @{deal.seller.instagram}
                      </a>
                    </td>
                    {/* <td className="px-6 py-4">{deal.clicks ?? 0}</td> */}
                    <td className="px-6 py-4">{deal.views ?? 0}</td>
                    <td className="px-6 py-4">
                      {deal.screenshot ? (
                        <a
                          onClick={() => {
                            fetchImage(deal.screenshot);
                          }}
                          target="_blank"
                          className="text-blue-400 cursor-pointer"
                        >
                          See viewership
                        </a>
                      ) : (
                        <div>No proof uploaded</div>
                      )}
                    </td>
                    <td className="px-6 py-4">
                      {deal.media ? (
                        <a
                          onClick={() => {
                            openMedia(deal.media);
                          }}
                          target="_blank"
                          className="text-blue-400 cursor-pointer"
                        >
                          Download story
                        </a>
                      ) : (
                        <div>No media uploaded</div>
                      )}
                    </td>
                    <td
                      className={`px-6 py-4 ${
                        deal.status != "In Review" &&
                        deal.status != "Not Accepted" &&
                        deal.status != "Accepted"
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      } ${
                        isActing == deal.id
                          ? "pointer-events-none opacity-50 flex"
                          : ""
                      }`}
                    >
                      {isActing == deal.id && (
                        <img
                          src="./icons/ui/load.gif"
                          className="w-8 h-8 mt-1"
                        />
                      )}
                      {deal.status == "Not Accepted" && (
                        <button
                          className="text-red-700 font-semibold mr-4"
                          onClick={() => {
                            actOn(deal);
                          }}
                        >
                          Recall Offer
                        </button>
                      )}
                      {deal.status == "In Review" && (
                        <div className="flex">
                          <input
                            type="number"
                            className="p-2 border rounded w-36 mr-4"
                            placeholder="Views"
                            value={viewInput[deal.id]}
                            onChange={(e) =>
                              setViewInput({ [deal.id]: e.target.value })
                            }
                          />
                          <button
                            className="text-green-700 font-semibold mr-4"
                            onClick={() => {
                              actOn(deal);
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                      )}
                      {deal.status == "Accepted" &&
                      deal.details.physicalProduct == true ? (
                        deal.details?.productSent ? (
                          <div>Product marked as sent</div>
                        ) : (
                          <div className="my-auto flex">
                            <input
                              type="text"
                              className="p-2 border rounded w-36 mr-4"
                              placeholder="Delivery date"
                              value={deliveryDate[deal.id]}
                              onChange={(e) =>
                                setDeliveryDate({ [deal.id]: e.target.value })
                              }
                            />
                            <button
                              className="text-blue-600 font-semibold mr-4 my-auto"
                              onClick={() => {
                                actOn(deal);
                              }}
                            >
                              Mark as sent
                            </button>
                          </div>
                        )
                      ) : (
                        deal.status == "Accepted" && <div>Not physical</div>
                      )}

                      {deal.status == "Accepted" &&
                        deal.details.physicalProduct == true && (
                          <div className="my-auto flex mt-2">
                            <input
                              type="text"
                              className="p-2 border rounded w-36 mr-4"
                              placeholder="Product Code"
                              value={productCode[deal.id]}
                              onChange={(e) =>
                                setProductCode({ [deal.id]: e.target.value })
                              }
                            />
                            <button
                              className="text-yellow-600 font-semibold mr-4 my-auto"
                              onClick={() => {
                                changeProductCode(deal, productCode[deal.id]);
                              }}
                            >
                              Change code
                            </button>
                          </div>
                        )}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
        <div className={`mr-8 w-64 ${!imageSrc && "hidden"}`}>
          {imageSrc == "loading" ? (
            <img src="./icons/ui/load.gif" className="w-16 mx-auto mt-12" />
          ) : (
            <div>
              <div className="flex mb-2">
                <a
                  href="#"
                  onClick={() => setImageSrc("")}
                  className="text-blue-400 text-sm mr-auto"
                >
                  Close
                </a>
                <a
                  href={imageSrc}
                  target="_blank"
                  className="text-blue-400 text-sm"
                >
                  Download
                </a>
              </div>
              <img src={imageSrc} className="w-full rounded border" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
