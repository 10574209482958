import ThemeButton from "./ThemeButton";

function Toolbar({ activeSport, setActiveSport }) {
  const sports = [
    "popular",
    "football",
    // "basketball",
    "baseball",
    "volleyball",
    "lacrosse",
    "soccer",
    "gymnastics",
    "tennis",
    "track",
    "hockey",
    "spirit",
  ];
  return (
    <div className="items-center justify-center hidden sm:flex mt-12">
      {sports.map((sport, index) => {
        return (
          <ThemeButton
            key={index}
            sport={sport}
            active={activeSport == sport}
            setActiveSport={setActiveSport}
          />
        );
      })}
    </div>
  );
}

export default Toolbar;
