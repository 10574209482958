function ThemeButton({ sport, active, setActiveSport }) {
  return (
    <div
      className={`btn-${sport} ${
        !active && "opacity-50"
      } hover:opacity-100 cursor-pointer w-fit mx-6`}
      onClick={() => {
        setActiveSport(sport);
      }}
    >
      <img
        className="w-10 mb-0 p-2 mx-auto"
        src={`./icons/${sport}.png`}
        alt={`${sport} icon`}
      />
      <span className="">
        {sport.slice(0, 1).toUpperCase() + sport.slice(1)}
      </span>
    </div>
  );
}

export default ThemeButton;
