import { httpsCallable } from "firebase/functions";
import { ref, uploadBytes } from "firebase/storage";
import { createRef, useEffect, useRef, useState } from "react";
import { functions, storage } from "./firebase-config";

export default function Athletes() {
  const [athletes, setAthletes] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isVerifying, setIsVerifying] = useState<string | undefined>(undefined);
  const [uploading, setUploading] = useState("");

  const [refresh, setRefresh] = useState(0);

  const fileInputRefs = useRef(new Map());
  useEffect(() => {
    const currentRefs = new Map();
    athletes.forEach((athlete) => {
      if (fileInputRefs.current.has(athlete.id)) {
        currentRefs.set(athlete.id, fileInputRefs.current.get(athlete.id));
      } else {
        currentRefs.set(athlete.id, createRef());
      }
    });
    fileInputRefs.current = currentRefs;
  }, [athletes]);

  const getFileInputRef = (athleteId) => {
    if (!fileInputRefs.current.has(athleteId)) {
      fileInputRefs.current.set(athleteId, createRef());
    }
    return fileInputRefs.current.get(athleteId);
  };

  useEffect(() => {
    getAllAthletes().then((athletes) => setAthletes(athletes as any[]));
  }, []);

  const getAllAthletes = async (skipLoading: boolean = false) => {
    if (!skipLoading) {
      setIsLoading(true);
    }
    const allAthletes = httpsCallable(functions, "allAthletes");
    const result = await allAthletes({ verified: false });
    setIsLoading(false);
    return result.data;
  };

  const setVerified = async (id: string, isVerified: boolean) => {
    setIsVerifying(id);
    const setVerified = httpsCallable(functions, "setVerified");
    await setVerified({ id, isVerified });
    const updatedAthletes = await getAllAthletes(true);
    setAthletes(updatedAthletes as any[]);
    setIsVerifying(undefined);
  };

  const exists = (...args: any[]) => args.every((arg) => arg != null);

  const removeAthlete = async (id: string) => {
    try {
      setIsVerifying(id);
      const removeAthlete = httpsCallable(functions, "removeAthlete");
      await removeAthlete({ id });
      const updatedAthletes = await getAllAthletes(true);
      setAthletes(updatedAthletes as any[]);
    } catch (e) {
      alert(
        "Error removing athlete. If they are verified, please unverify them first."
      );
    } finally {
      setIsVerifying(undefined);
    }
  };

  const handleFileChange = async (e, id) => {
    console.log("File change detected", id);
    const file = e.target.files[0];

    if (!file) {
      alert("No file selected.");
      return;
    }

    // Create an image element
    const image = new Image();
    image.src = URL.createObjectURL(file);

    // Wait for the image to load
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    // Create a canvas element
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      alert("Error creating canvas context.");
      return;
    }

    // Set canvas dimensions to match the image
    canvas.width = image.width;
    canvas.height = image.height;

    // Draw the image onto the canvas
    ctx.drawImage(image, 0, 0);

    // Convert the canvas to a blob in JPG format
    canvas.toBlob(
      (blob) => {
        if (blob) {
          const jpgFile = new File([blob], `${file.name.split(".")[0]}.jpg`, {
            type: "image/jpeg",
          });
          handleUpload(jpgFile, id);
        } else {
          alert("Error converting image to JPEG.");
        }
      },
      "image/jpeg",
      0.9
    );

    const inputRef = getFileInputRef(id);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleUpload = async (data: Blob, athleteId: string) => {
    console.log("Uploading for ", athleteId);
    if (!data) {
      alert("Please select a file first!");
      return;
    }

    setUploading(athleteId);
    const storageRef = ref(storage, `${athleteId}/profile.jpg`);

    try {
      await uploadBytes(storageRef, data);
      console.log("Uploaded for ", athleteId);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file.");
    }

    setUploading("");
    setRefresh((prev) => prev + 1);
  };

  return (
    <div className="deals mt-4">
      <div className="relative overflow-x-auto w-full">
        {isLoading && (
          <div className="fixed w-full bg-clear h-screen z-10">
            <img src="./icons/ui/load.gif" className="w-16 mx-auto mt-12" />
          </div>
        )}
        <table
          className="mx-8 box-border text-sm text-left rtl:text-right text-gray-500"
          style={{ width: "calc(100% - 4rem)" }}
        >
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Instagram
              </th>
              <th scope="col" className="px-6 py-3">
                Address
              </th>
              <th scope="col" className="px-6 py-3">
                Sport
              </th>
              <th scope="col" className="px-6 py-3">
                School
              </th>
              <th scope="col" className="px-6 py-3">
                Verified?
              </th>
              <th scope="col" className="px-6 py-3">
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {athletes
              .filter((a) => exists(a.firstName, a.lastName, a.instagram))
              .sort((a, b) => a.instagram.localeCompare(b.instagram))
              .map((athlete) => (
                <tr
                  className={`bg-white border-b hover:bg-gray-100 ${
                    isLoading && "blur-sm"
                  }`}
                  key={athlete.id}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    aria-label="test"
                    title={athlete.id}
                  >
                    <input
                      type="file"
                      onChange={(e) => {
                        handleFileChange(e, athlete.id);
                      }}
                      ref={getFileInputRef(athlete.id)}
                      className="hidden"
                    />
                    <img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/launchpoint-a0133.appspot.com/o/" +
                        athlete.id +
                        "%2Fprofile.jpg?alt=media&refresh=" +
                        refresh
                      }
                      className={`w-6 h-6 inline mr-2 cursor-pointer ${
                        uploading == athlete.id &&
                        "opacity-50 pointer-events-none"
                      }`}
                      onError={(e) => {
                        e.currentTarget.src = "./icons/ui/upload.png";
                      }}
                      onClick={(e) => {
                        const inputRef = getFileInputRef(athlete.id);
                        if (!inputRef.current) return;
                        inputRef.current.click();
                      }}
                    />
                    {athlete.firstName + " " + athlete.lastName}
                  </th>
                  <td className="px-6 py-4 text-blue-500">
                    <a
                      href={`https://instagram.com/${athlete.instagram}`}
                      target="_blank"
                    >
                      @{athlete.instagram}
                    </a>
                  </td>
                  <td className="px-6 py-4">
                    {(athlete.address?.street ?? "") +
                      " " +
                      (athlete.address?.streetTwo ?? "") +
                      " " +
                      (athlete.address?.city ?? "") +
                      " " +
                      (athlete.address?.state ?? "") +
                      " " +
                      (athlete.address?.zip ?? "")}
                  </td>
                  <td className="px-6 py-4">{athlete.sport}</td>
                  <td className="px-6 py-4">{athlete.school}</td>
                  <td
                    className={`px-6 py-4 ${
                      athlete.isVerified ? "text-green-700" : ""
                    }`}
                  >
                    {athlete.isVerified ? "Yes" : "No"}
                  </td>
                  <td
                    className={`px-6 py-4 flex ${
                      isVerifying == athlete.id &&
                      "pointer-events-none opacity-50"
                    }`}
                  >
                    {isVerifying == athlete.id ? (
                      <img src="./icons/ui/load.gif" className="w-6 h-6" />
                    ) : (
                      <div className="w-6" />
                    )}
                    {athlete.isVerified ? (
                      <button
                        className="font-semibold text-yellow-700 hover:text-yellow-900"
                        onClick={() => setVerified(athlete.id, false)}
                      >
                        Unverify
                      </button>
                    ) : (
                      <>
                        <button
                          className="font-semibold text-green-600 hover:text-green-900 mr-4"
                          onClick={() => setVerified(athlete.id, true)}
                          aria-label={athlete.id}
                        >
                          Verify
                        </button>
                      </>
                    )}
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(athlete.id);
                        alert("Copied " + athlete.id);
                      }}
                      className="font-semibold text-blue-600 hover:text-blue-900 ml-4"
                    >
                      Copy ID
                    </button>
                    <button
                      onClick={() => {
                        const confirmed = window.confirm(
                          "Are you sure you want to remove this athlete?"
                        );
                        if (confirmed) {
                          removeAthlete(athlete.id);
                        }
                      }}
                      className="font-semibold text-red-700 hover:text-red-900 ml-4"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
