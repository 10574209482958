import { httpsCallable } from "firebase/functions";
import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { functions } from "../firebase-config";
import AthleteProfile from "./AthleteProfile";

function AthleteGrid({ activeSport, searchQuery }) {
  const [athleteList, setAthleteList] = useState<any[]>([]);
  const { selectedAthletes, setSelectedAthletes } = useContext(AppContext);

  const toggleSelected = (athlete) => {
    return;
    if (selectedAthletes.filter((a) => a.id == athlete.id).length > 0) {
      setSelectedAthletes(selectedAthletes.filter((a) => a.id != athlete.id));
    } else {
      setSelectedAthletes([...selectedAthletes, athlete]);
    }
  };

  useEffect(() => {
    getAllAthletes().then((athletes) => setAthleteList(athletes as any[]));
  }, []);

  const getAllAthletes = async () => {
    const allAthletes = httpsCallable(functions, "allAthletes");
    const result = await allAthletes({ verified: true });
    console.log((result.data as any).filter((a) => a.sport == "Lacrosse"));
    return result.data;
  };

  if (athleteList.length === 0) {
    return (
      <div>
        <img
          src="./icons/ui/load.gif"
          className="mx-auto mt-8 w-20 h-20"
          alt="Loading..."
        />
      </div>
    );
  }

  return (
    <>
      <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-auto gap-2 mx-8 place-items-center">
        <>
          {athleteList
            .filter(
              (item) =>
                item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
                item.instagram != "adam__bn" &&
                item.instagram != "Trhee3" &&
                !item.name.includes("undefined") &&
                /\b\w+(\s+\w+)+\b/.test(item.name)
            )
            .map((athlete, index) => {
              return (
                <AthleteProfile
                  className={
                    activeSport != "popular" &&
                    activeSport.toLowerCase() !=
                      (athlete.sport.split(" ")[0] ?? "").toLowerCase()
                      ? "hidden"
                      : ""
                  }
                  key={index}
                  isSelected={
                    selectedAthletes.filter((a) => a.id == athlete.id).length >
                    0
                  }
                  athlete={athlete}
                  onClick={() => {
                    toggleSelected(athlete);
                  }}
                />
              );
            })}
        </>
      </div>
    </>
  );
}

export default AthleteGrid;
