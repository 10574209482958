import { useContext, useState } from "react";
import AppContext from "../AppContext";
import OfferForm from "./OfferForm";

function DynamicCampaignForm({}) {
  const { setActivePage, setCampaignType } = useContext(AppContext);

  //Dynamic Campaign Form specific state
  const [budgetString, setBudgetString] = useState("$500");

  //Shared state
  const [totalPrice, setTotalPrice] = useState(50000);

  const updateBudget = (value) => {
    const clean = value.replace(/[^0-9]/g, "");
    var number = 0;
    if (clean != "") {
      number = parseInt(clean);
    }
    setBudgetString("$" + number);
    setTotalPrice(number * 100);
  };

  const exitDetails = () => {
    setActivePage("home");
    setCampaignType("");
  };

  return (
    <div className="px-8 w-full">
      <div
        className="ml-8 font-bold text-left w-fit my-4 cursor-pointer"
        onClick={exitDetails}
      >
        ←Back
      </div>

      <div className="w-full flex justify-center">
        <div className="w-1/3 mx-12 flex-row">
          <div className="ml-0 text-center font-bold text-xl mt-8">Options</div>
          <div className="w-full border-b border-grey-400 my-4"></div>
          <div className="w-full h-20 flex mb-6">
            <div className="ml-4 text-left w-full">
              <label
                htmlFor="budget"
                className="block mb-2 text-sm font-medium text-gray-900 text-left"
              >
                Budget
              </label>
              <input
                type="text"
                value={budgetString}
                onChange={(e) => updateBudget(e.target.value)}
                id="budget"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="$500"
                required
              />

              <label
                htmlFor="targeting"
                className="block mt-6 mb-2 text-sm font-medium text-gray-900 text-left"
              >
                Targeting
              </label>
              <select
                id="area"
                style={{
                  borderRight: "16px solid transparent",
                  outline: "1px solid #ddd",
                }}
                className={`mt-3 bg-gray-50 text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-2.5`}
              >
                <option value="dense">Ohio State</option>
              </select>
            </div>
          </div>
        </div>

        <div className={`w-2/3 mx-12`}>
          <div className="ml-0 text-center font-bold text-xl mt-8">
            Your Details
          </div>
          <div className="w-full border-b border-grey-400 my-4"></div>
          <OfferForm totalPrice={totalPrice} />
        </div>
      </div>
    </div>
  );
}
export default DynamicCampaignForm;
