import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDqjgp1WRxM8JhjhJ-EwQa9FiqP8dzDz-4",
  authDomain: "launchpoint-a0133.firebaseapp.com",
  projectId: "launchpoint-a0133",
  storageBucket: "launchpoint-a0133.appspot.com",
  messagingSenderId: "184835488165",
  appId: "1:184835488165:web:310be6b45d77b8867c904c",
  measurementId: "G-S5BWCCHJTE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const storage = getStorage(app);

export { analytics, auth, functions, storage };
