import { httpsCallable } from "firebase/functions";
import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import { functions } from "../firebase-config";

function OfferForm({
  totalPrice,
  campaign,
  back,
}: {
  totalPrice: number;
  campaign?: any;
  back?: any;
}) {
  const { uid } = useContext(AppContext);

  const [formData, setFormData] = useState({
    brand: "",
    title: "",
    subtitle: "",
    message: "",
    date: "",
    cpm: 15,
    link: undefined,
    linkTitle: undefined,
    tagAccount: undefined,
    physicalProduct: false,
    offerLink: undefined,
    offerCode: undefined,
    reshareLink: undefined,
    type: "video",
  });

  const [includesLink, setIncludesLink] = useState(false);
  const [physicalProduct, setPhysicalProduct] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentFlowInProgress, setPaymentFlowInProgress] = useState(false);

  useEffect(() => {
    return () => {
      setClientSecret(null);
      setPaymentFlowInProgress(false);
    };
  }, []);

  useEffect(() => {
    if (campaign) {
      setFormData({
        brand: campaign.details.brand || "",
        title: campaign.details.title || "",
        subtitle: campaign.details.subtitle || "",
        message: campaign.details.message.replace(/<br>/g, "\n") || "",
        date: campaign.details.date || "",
        cpm: campaign.cpm || 15,
        tagAccount: campaign.details.tagAccount || "",
        link: campaign.details.link || "",
        linkTitle: campaign.details.linkTitle || "",
        physicalProduct: campaign.details.physicalProduct || false,
        offerLink: campaign.details.offerLink || "",
        offerCode: campaign.details.offerCode || "",
        reshareLink: campaign.details.reshareLink || "",
        type: campaign.details.type || "",
      });
      setPhysicalProduct(campaign.details.physicalProduct || false);
      setIncludesLink(campaign.details.link != undefined);
    }
  }, [campaign]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      physicalProduct: physicalProduct,
      includesLink: includesLink,
    }));
  }, [physicalProduct, includesLink]);

  const startPayment = async (e) => {
    e.preventDefault();
    setPaymentFlowInProgress(true);
    if (campaign != null) {
      const editCampaign = httpsCallable(functions, "editCampaign");
      console.log(formData);
      editCampaign({
        campaignId: campaign.id,
        details: {
          ...formData,
          message: formData.message.replace(/\n/g, "<br>"),
          link: includesLink ? formData.link : null,
          linkTitle: includesLink ? formData.linkTitle : null,
        },
      })
        .then((result) => {
          console.log("Success: " + result.data);
          location.reload();
        })
        .catch((error) => {
          console.log("Error: ", error);
          setPaymentFlowInProgress(false);
          alert("An error occured. Please try again.");
        });
      return;
    } else {
      const newCampaign = httpsCallable(functions, "newCampaign");
      newCampaign({
        details: {
          ...formData,
          message: formData.message.replace(/\n/g, "<br>"),
          link: includesLink ? formData.link : null,
          linkTitle: includesLink ? formData.linkTitle : null,
        },
        cpm: 15,
        budget: totalPrice,
        targeting: "ohiostate",
      })
        .then((result) => {
          console.log("Success: " + result.data);
          location.reload();
        })
        .catch((error) => {
          console.log("Error: ", error);
          setPaymentFlowInProgress(false);
          alert("An error occured. Please try again.");
        });
    }
  };

  return (
    <>
      {paymentFlowInProgress && (
        <div className="absolute my-4 pb-4 w-2/3 pr-24" style={{ zIndex: 1 }}>
          {clientSecret ? (
            <></>
          ) : (
            // <PaymentForm clientSecret={clientSecret} />
            <div className="w-full pr-24">
              <div className="font-bold text-center">Loading...</div>
              <img
                src="./icons/ui/load.gif"
                className="w-24 mx-auto mb-2 mt-0"
              />
            </div>
          )}
        </div>
      )}

      <form
        onSubmit={startPayment}
        className={`${
          uid && !paymentFlowInProgress ? "opacity-100" : "blur-sm"
        } ${clientSecret && "opacity-0"}`}
      >
        <div className="mb-6">
          <label
            htmlFor="title"
            className="block mb-2 text-sm font-medium text-gray-900 text-left"
          >
            Offer Type
          </label>
          <select
            id="type"
            onChange={handleInputChange}
            value={formData.type}
            style={{
              borderRight: "16px solid transparent",
              outline: "1px solid #ddd",
            }}
            className="bg-gray-50 text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full p-2.5 "
            required
          >
            <option value="reshare">Reshare another post</option>
            <option value="photo">Custom photo of the athlete</option>
            <option value="video">Custom video of the athlete</option>
          </select>
          {formData.type == "reshare" ? (
            <>
              <input
                type="text"
                id="reshareLink"
                className="mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="https://instagram.com/post to reshare"
                onChange={handleInputChange}
              />
            </>
          ) : (
            <div className="text-gray-600 w-full text-left mt-2 text-sm">
              {/* There is an additional ${(TYPE_PRICES[type] / 100).toFixed(0)}{" "}
              charge per {type} */}
            </div>
          )}
        </div>

        <div className="mb-6 flex">
          <div className="w-1/2 mr-8">
            <label
              htmlFor="brand"
              className="block mb-2 text-sm font-medium text-gray-900 text-left"
            >
              Brand
            </label>
            <input
              type="text"
              id="brand"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="Apple"
              onChange={handleInputChange}
              value={formData.brand}
              required
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900 text-left"
            >
              Display price (subheader)
            </label>
            <input
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="$10 per 1,000 views"
              onChange={handleInputChange}
              value={formData.title}
              required
            />
          </div>
        </div>

        <div className="mb-6 flex space-x-8">
          <div className="w-1/2">
            <label
              htmlFor="date"
              className="block mb-2 text-sm font-medium text-gray-900 text-left"
            >
              Due date
            </label>
            <input
              type="text"
              id="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="January 1, 2025"
              onChange={handleInputChange}
              value={formData.date}
              required
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="date"
              className="block mb-2 text-sm font-medium text-gray-900 text-left"
            >
              Account to tag (optional)
            </label>
            <input
              type="text"
              id="tagAccount"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="@launchpoint"
              onChange={handleInputChange}
              value={formData.tagAccount}
            />
          </div>
        </div>

        <div className="mb-6 flex space-x-8">
          <div className="w-1/2">
            <label
              htmlFor="subtitle"
              className="block mb-2 text-sm font-medium text-gray-900 text-left"
            >
              Subtitle
            </label>
            <input
              type="text"
              id="subtitle"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="Show off our greatest AirPods yet"
              onChange={handleInputChange}
              value={formData.subtitle}
              required
            />
          </div>
          <div className="w-1/2">
            <label
              htmlFor="cpm"
              className="block mb-2 text-sm font-medium text-gray-900 text-left"
            >
              CPM
            </label>
            <input
              type="number"
              id="cpm"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="15"
              onChange={handleInputChange}
              value={formData.cpm}
              required
            />
          </div>
        </div>

        <div className="mb-6">
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-gray-900 text-left"
          >
            Full instructions
          </label>
          <textarea
            id="message"
            rows={4}
            onChange={handleInputChange}
            value={formData.message}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
            placeholder="Order the Airpods Pro from the link below the code in your notes. Post a video dancing with the AirPods, and mention the noise cancellation feature at least once."
          ></textarea>
        </div>

        <div className="flex items-start mb-6">
          <div className="flex items-center h-5">
            <input
              id="includesLink"
              type="checkbox"
              checked={includesLink}
              onChange={(e) => setIncludesLink(e.target.checked)}
              className="w-4 h-4 cursor-pointer border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
            />
          </div>
          <label
            htmlFor="includesLink"
            className="ms-2 text-sm font-medium text-gray-900 cursor-pointer"
          >
            Include link sticker
          </label>
        </div>
        {includesLink && (
          <>
            <div className="mb-4 mt-[-12px]">
              <input
                type="text"
                id="linkTitle"
                onChange={handleInputChange}
                value={formData.linkTitle}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Link Sticker Title"
              />
            </div>
            <div className="mb-6">
              <input
                type="text"
                id="link"
                onChange={handleInputChange}
                value={formData.link}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="https://example.com/destination url"
              />
              <div className="text-gray-600 w-full text-left mt-2 text-sm">
                Clicks will include ?utm_source=launchpoint&utm_content=
                <i>The Athlete's Username</i>
              </div>
            </div>
          </>
        )}

        <div className="flex items-start mb-2 mt-4">
          <div className="flex items-center h-5">
            <input
              id="physicalProduct"
              type="checkbox"
              checked={physicalProduct}
              onChange={(e) => setPhysicalProduct(e.target.checked)}
              className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
            />
          </div>
          <label
            htmlFor="physicalProduct"
            className="ms-2 text-sm font-medium text-gray-900 cursor-pointer"
          >
            Send a free physical product
          </label>
        </div>

        {physicalProduct && (
          <>
            <div className="mb-6">
              <label
                htmlFor="offerLink"
                className="block mb-2 text-sm font-medium text-gray-900 text-left"
              >
                Link to product
              </label>
              <input
                type="link"
                id="offerLink"
                onChange={handleInputChange}
                value={formData.offerLink}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="https://apple.com/airpods"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="offerCode"
                className="block mb-2 text-sm font-medium text-gray-900 text-left"
              >
                Athlete checkout code
                <div className="text-gray-600 text-sm font-light">
                  Enter one code for everyone, or multiple codes separated by
                  commas for each athlete. Launchpoint will only do as many
                  deals as you have codes.
                </div>
              </label>
              <input
                type="text"
                id="offerCode"
                onChange={handleInputChange}
                value={formData.offerCode}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="LAUNCHPOINT_0001, LAUNCHPOINT_0002, LAUNCHPOINT_0003..."
                required
              />
            </div>
          </>
        )}
        {/* <div className="mt-6 block mb-2 text-sm text-gray-600 text-left">
          By clicking Submit, you understand:
          <br />
          - Launchpoint will automatically recruit the best athletes for your
          deal
          <br />- You'll pay $15 per 1,000 post impressions (proof of viewership
          will be provided)
          {includesLink && (
            <>
              <br />- You'll pay $1 per link sticker click
            </>
          )}
          <br />- You agree to our{" "}
          <a href="#" className="text-blue-700">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="#" className="text-blue-700">
            Privacy Policy
          </a>
        </div> */}
        <button
          type="submit"
          className="mt-4 mb-12 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {totalPrice != -1
            ? "Add $" + ((totalPrice || 0) / 100).toLocaleString("en-US")
            : "Save"}
        </button>
      </form>
    </>
  );
}

export default OfferForm;
